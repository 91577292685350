import { Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { IApp, IHardcodedRoute, ISuite } from '@soleran/contracts';
import { AppService } from '@soleran/ngx-app';
import { AuthService } from '@soleran/ngx-auth';
import { HardcodedRoutesService, SuiteService } from '@soleran/ngx-suite';
import { catchError, of, shareReplay, startWith, Subject, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SuiteStateService {
	//TODO; Finish
	private _refreshSuites$ = new Subject<void>();
	private _refreshApps$ = new Subject<void>();
	private _refreshHardcodedRoutes$ = new Subject<void>();

	suites$ = this._refreshSuites$.pipe(
		startWith(0),
		switchMap(() => this._authService.onCurrentUser),
		switchMap((user) => {
			if (!user) return of([]);
			return this._suiteService.get().pipe(
				catchError((err) => {
					console.error(err);
					return of([] as ISuite[]);
				})
			);
		}),
		shareReplay(1)
	);
	suites = toSignal(this.suites$, { initialValue: [] });

	apps$ = this._refreshSuites$.pipe(
		startWith(0),
		switchMap(() => this._authService.onCurrentUser),
		switchMap((user) => {
			if (!user) return of([]);
			return this._appService.get().pipe(
				catchError((err) => {
					console.error(err);
					return of([] as IApp[]);
				})
			);
		})
	);
	apps = toSignal(this.apps$, { initialValue: [] });

	hardcodedRoutes$ = this._refreshSuites$.pipe(
		startWith(0),
		switchMap(() => this._authService.onCurrentUser),
		switchMap((user) => {
			if (!user) return of([]);
			return this._hardcodedRouteService.get().pipe(
				catchError((err) => {
					console.error(err);
					return of([] as IHardcodedRoute[]);
				})
			);
		}),
		shareReplay(1)
	);
	hardcodedRoutes = toSignal(this.hardcodedRoutes$, { initialValue: [] });

	update(options?: { suites?: boolean; apps?: boolean; hardcodedRoutes?: boolean }): void {
		const { suites, apps, hardcodedRoutes } = options ?? {
			suites: true,
			apps: true,
			hardcodedRoutes: true
		};
		if (!!suites) this._refreshSuites$.next();
		if (!!apps) this._refreshApps$.next();
		if (!!hardcodedRoutes) this._refreshHardcodedRoutes$.next();
	}

	constructor(
		private _authService: AuthService,
		private _suiteService: SuiteService,
		private _appService: AppService,
		private _hardcodedRouteService: HardcodedRoutesService
	) {}
}
